import React from 'react';
import s from './s.module.less';
import dayjs from 'dayjs';
import { DoubleLeftOutlined, DoubleRightOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import { Event, ServerEvent } from 'types/calendar';

import { Button, Radio, Spin, Popover, Modal, notification } from 'antd';
import { getMonthDaysMatrix, getWeekDaysArray, getTimeStr, getDateKey, getTimeStrForGoogleCalandar } from 'utils/calendar';
import GoogleStore from 'store/Google';
import { calendarEventFetch } from 'api/google';
import ProviderStore from 'store/Provider';
import { useNavigate } from 'react-router-dom';
import Month from './components/Month';
import Week from './components/Week';
import Day from './components/Day';
import AddAppointmentModal from 'components/AddAppointmentModal';
import AddEventModal from './components/AddEventModal';
import AddBlocktimeModal from './components/AddBlocktimeModal';
import EditCommonEventModal from './components/EditCommonEventModal';
import { PATH } from 'constants/path';
import { EventData } from 'data/calendar';
import AuthGoogleCalendar from 'components/AuthGoogleCalendar';
import useChannel from 'hooks/useChannel';
import useService from 'hooks/useService';
import { isAppointment, isBpEvent, isBlockTime } from './utils';
import commonS from 'styles/common.module.less';
import refreshIcon from 'assets/common/refresh.svg';

enum EMode {
    MONTH = 'month',
    WEEK = 'week',
    DAY = 'day',
}
const TIME_FORMAT = 'YYYY-MM-DD';

const Page = () => {
    const [api, contextHolder] = notification.useNotification();
    const [getGoogle] = GoogleStore.useStore();
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);
    const googleClientReady = getGoogle('clientReady');
    const calendarReady = getGoogle('calendarReady');
    const [showAddEvent, setShowAddEvent] = React.useState(false);
    const [showAddAppointment, setShowAddAppointment] = React.useState(false);
    const [showAddBlocktime, setShowAddBlocktime] = React.useState(false);
    const [showCommonEdit, setShowCommonEdit] = React.useState(false);
    const [currentEvent, setCurrentEvent] = React.useState<Event | undefined>(undefined);
    const [showAuth, setShowAuth] = React.useState(false);
    const [getUser] = ProviderStore.useStore();
    const [services, loadingServices, fetchServices] = useService();
    const [channels, channelsLoading] = useChannel();
    const user = getUser('data');
    const loadingUser = getUser('loading');

    // React.useEffect(() => {
    //     if (user?.email && !loadingUser && !user.refreshToken) {
    //         setShowAuth(true);
    //     }
    // }, [user, loadingUser]);

    const [mode, setMode] = React.useState<EMode>(EMode.WEEK);
    const [monthIndex, setMonthIndex] = React.useState(dayjs().month());
    const [date, setDate] = React.useState(dayjs().date());
    const [currentDate, setCurrentDate] = React.useState<dayjs.Dayjs>(dayjs()); // not index
    const [currenMonthDaysMatrix, setCurrentMonthDaysMatrix] = React.useState(getMonthDaysMatrix(monthIndex));
    const [currenWeekDaysArray, setCurrenWeekDaysArray] = React.useState(getWeekDaysArray(date));
    const [allEvents, setAllEvents] = React.useState<Event[]>([]);

    // const endTime = dayjs('2023-02-01T00:00:00+08:00');
    // console.log('--------');
    // console.log('2023-02-01T00:00:00+08:00');
    // console.log(endTime.hour());
    // console.log(endTime.minute());
    // console.log(formatDate(endTime.hour(1).minute(0), 'YYYY-MM-DDThh:mm:ssZ'));

    const createContent = (
        <div className={s.createWrap}>
            <Button
                type="text"
                onClick={() => {
                    setShowAddAppointment(true);
                }}
                block
            >Appointments
            </Button>
            <Button
                type="text"
                onClick={() => {
                    setShowAddEvent(true);
                }}
                block
            >Events
            </Button>
            <Button
                type="text"
                onClick={() => {
                    setShowAddBlocktime(true);
                }}
                block
            >Block time
            </Button>
        </div>
    );

    // const dateEventMap = React.useMemo(() => {
    //     const dateMap:Record<string, Event[]> = {};
    //     for (let i = 0; i < allEvents.length; i++) {
    //         const event = allEvents[i];
    //         const startDay = getDateKey(dayjs(new Date(event.start?.dateTime)));
    //         const endDay = getDateKey(dayjs(new Date(event.end?.dateTime)));
    //         const endTime = dayjs(new Date(event.end?.dateTime));
    //         if (startDay !== endDay) {
    //             if (dayjs(new Date(event.end?.dateTime)).date() - dayjs(new Date(event.start?.dateTime)).date() > 1) {
    //                 //do not consider mutiple day event
    //                 continue;
    //             }
    //         }
    //         if (!dateMap[startDay]) {
    //             dateMap[startDay] = [];
    //         }
    //         dateMap[startDay].push(event);
    //         if (startDay !== endDay && endTime.hour() !== 0 && endTime.minute() !== 0) {
    //             if (!dateMap[endDay]) {
    //                 dateMap[endDay] = [];
    //             }
    //             dateMap[endDay].push({
    //                 ...event,
    //                 start: {
    //                     //2023-02-01T00:00:00+08:00
    //                     dateTime: formatDate(endTime.hour(0).minute(0), 'YYYY-MM-DDTZhh:mm:ssZ'),
    //                     timeZone: event.start.timeZone,
    //                 },
    //             });
    //         }
    //     }
    //     return dateMap;
    // }, [allEvents]);

    const dateMap:Record<string, Event[]> = {};
    for (let i = 0; i < allEvents.length; i++) {
        const event = allEvents[i];
        const startDay = getDateKey(dayjs(new Date(event.start?.dateTime)));
        const endDay = getDateKey(dayjs(new Date(event.end?.dateTime)));
        const startTime = dayjs(new Date(event.start?.dateTime));
        const endTime = dayjs(new Date(event.end?.dateTime));
        if (startDay !== endDay) {
            if (dayjs(new Date(event.end?.dateTime)).date() - dayjs(new Date(event.start?.dateTime)).date() > 1) {
                //do not consider mutiple day event
                continue;
            }
        }

        //end date - start date === 0

        if (!dateMap[startDay]) {
            dateMap[startDay] = [];
        }

        if (startDay !== endDay) {
            dateMap[startDay].push({
                ...event,
                end: {
                    //2023-02-01T00:00:00+08:00
                    dateTime: getTimeStrForGoogleCalandar(startTime.hour(23).minute(59), 'YYYY-MM-DDTZhh:mm:ssZ'),
                    timeZone: event.start.timeZone,
                },
            });
        } else {
            dateMap[startDay].push(event);
        }

        if (startDay !== endDay && endTime.hour() !== 0 && endTime.minute() !== 0) {
            if (!dateMap[endDay]) {
                dateMap[endDay] = [];
            }

            dateMap[endDay].push({
                ...event,
                start: {
                    //2023-02-01T00:00:00+08:00
                    dateTime: getTimeStrForGoogleCalandar(endTime.hour(0).minute(0), 'YYYY-MM-DDTZhh:mm:ssZ'),
                    timeZone: event.start.timeZone,
                },
            });
        }
    }
    const dateEventMap = dateMap;
    // console.log('2023-02-22T09:00:00');
    // console.log(getTimeStrForGoogleCalandar(dayjs('2023-02-22T09:00:00').tz('America/Toronto', true)));
    // console.log(getTimeStrForGoogleCalandar(dayjs('2023-02-22T09:00:00').tz('America/Toronto', false)));
    const fetchEvents = (start:string, end:string) => {
        setLoading(true);
        calendarEventFetch({
            start,
            end,
        }).then(({ data, error }: { data:{ data:ServerEvent[] }, error:unknown }) => {
            if (!error) {
                setAllEvents(data?.data?.filter(Boolean).map(EventData.transferServerEventToGoogleEvent) || []);
                //setAllEvents((guiruiData as any).data?.filter(Boolean).map(EventData.transferServerEventToGoogleEvent) || []);
            }
            setLoading(false);
        });
    };

    React.useEffect(() => {
        setCurrentMonthDaysMatrix(getMonthDaysMatrix(monthIndex));
    }, [monthIndex]);

    React.useEffect(() => {
        setCurrenWeekDaysArray(getWeekDaysArray(date));
    }, [date]);

    React.useEffect(() => {
        if (!googleClientReady || !calendarReady) {
            return;
        }
        const targetDate = mode === EMode.MONTH ? dayjs(new Date(dayjs().year(), monthIndex, 1)) : dayjs(new Date(dayjs().year(), dayjs().month(), date));
        if (mode === EMode.MONTH) {
            const timeMin = getTimeStr(targetDate.add(-1, 'month'), TIME_FORMAT);
            const timeMax = getTimeStr(targetDate.add(1, 'month'), TIME_FORMAT);
            fetchEvents(timeMin, timeMax);
            return;
        }
        if (mode === EMode.WEEK) {
            const timeMin = getTimeStr(targetDate.add(-1, 'week'), TIME_FORMAT);
            const timeMax = getTimeStr(targetDate.add(1, 'week'), TIME_FORMAT);
            fetchEvents(timeMin, timeMax);
        }
    }, [googleClientReady, calendarReady, monthIndex, date, mode]);

    const onNext = () => {
        if (mode === EMode.MONTH) {
            setMonthIndex(monthIndex + 1);
        }
        if (mode === EMode.WEEK) {
            setDate(date + 7);
        }
        if (mode === EMode.DAY) {
            setCurrentDate(currentDate.add(1, 'day'));
        }
    };

    const onPrevious = () => {
        if (mode === EMode.MONTH) {
            setMonthIndex(monthIndex - 1);
        }
        if (mode === EMode.WEEK) {
            setDate(date - 7);
        }
        if (mode === EMode.DAY) {
            setCurrentDate(currentDate.add(-1, 'day'));
        }
    };

    const onToday = () => {
        setMonthIndex(dayjs().month());
        setDate(dayjs().date());
        setCurrentDate(dayjs());
    };

    const onRefresh = () => {
        const targetDate = mode === EMode.MONTH ? dayjs(new Date(dayjs().year(), monthIndex, 1)) : dayjs(new Date(dayjs().year(), dayjs().month(), date));
        const timeMin = getTimeStr(targetDate.add(-1, 'month'), TIME_FORMAT);
        const timeMax = getTimeStr(targetDate.add(1, 'month'), TIME_FORMAT);
        fetchEvents(timeMin, timeMax);
    };

    const onSetting = () => {
        navigate(`${PATH.DASHBOARD}/${PATH.APPOINTMENT_SETTING}`);
    };

    const onEvent = (event:Event) => {
        console.log({ event });
        setCurrentEvent(event);
        if (isBlockTime(event)) {
            setShowAddBlocktime(true);
            return;
        }
        if (isBpEvent(event)) {
            setShowAddEvent(true);
            return;
        }
        if (isAppointment(event, services, channels)) {
            setShowAddAppointment(true);
            return;
        }
        setShowCommonEdit(true);
    };

    let yearDisplay = dayjs(new Date(dayjs().year(), monthIndex)).format(
        'MMMM YYYY',
    );
    if (mode === EMode.WEEK) {
        yearDisplay = dayjs(new Date(dayjs().year(), dayjs().month(), date)).format(
            'MMMM YYYY',
        );
    }

    return (
        <div className={s.wrap}>
            {contextHolder}
            <div className={s.clWrap}>
                <div className={s.header}>
                    <div className={s.left}>
                        <h1>Appointment</h1>
                        <div className={s.tip}>
                            Manage all your appointment from different channels
                        </div>
                    </div>
                    <div className={s.right}>
                        <Button
                            className={commonS.lightBtn}
                            onClick={onSetting}
                            style={{
                                marginRight: '8px',
                            }}
                        >
                            Setting
                        </Button>

                        <Popover placement="bottomLeft" title={null} content={createContent} trigger="hover">
                            <Button icon={<PlusOutlined />} type="primary">
                                Create
                                <DownOutlined />
                            </Button>
                        </Popover>
                    </div>
                </div>
                <div className={s.calendarHeader}>
                    <div className={s.left}>
                        <span className={s.date}>
                            {yearDisplay}
                        </span>
                        <span
                            className={s.nav}
                            onClick={onPrevious}
                        >
                            <DoubleLeftOutlined />
                        </span>
                        <span
                            onClick={onNext}
                            className={s.nav}
                        >
                            <DoubleRightOutlined />
                        </span>
                        <span
                            className={s.today}
                            onClick={onToday}
                        >
                            Today
                        </span>

                    </div>
                    <div className={s.mid}>
                        <Radio.Group
                            onChange={(e) => {
                                if (e.target.value === mode) {
                                    return;
                                }
                                onToday();
                                setMode(e.target.value);
                            }}
                            value={mode}
                        >
                            <Radio.Button value={EMode.DAY}>Day</Radio.Button>
                            <Radio.Button value={EMode.WEEK}>Week</Radio.Button>
                            <Radio.Button value={EMode.MONTH}>Month</Radio.Button>
                        </Radio.Group>
                    </div>
                    <div className={s.right}>
                        <Spin spinning={loading} rootClassName={s.spin}>
                            <div className={s.loading} />
                        </Spin>
                        <Button
                            className={s.rightBtn}
                            onClick={onRefresh}
                        >
                            <img src={refreshIcon} /> Refresh
                        </Button>
                    </div>
                </div>

                <div className={s.monthWrap}>
                    {showAuth &&
                    <Modal
                        title="Before we start"
                        width="600px"
                        destroyOnClose
                        open
                        closeIcon={<span />}
                        okText="Auth calendar permission"
                        okButtonProps={{
                            loading,
                        }}
                        footer={
                            <div className={s.footerWrap}>
                                <div className={s.btnWrap}>
                                    <AuthGoogleCalendar
                                        type="primary"
                                        successCallbackPath={`${PATH.DASHBOARD}/${PATH.APPOINTMENT}`}
                                    />
                                </div>
                            </div>
                        }
                    >
                        <div className={s.authWrap}>
                            <div className={s.authTip}>To ensure seamless syncing of your appointments, please grant us secure access to your Google Calendar account.</div>
                        </div>
                    </Modal>

                    }
                    {mode === EMode.DAY &&
                    <Day
                        onEventClick={onEvent}
                        day={currentDate}
                        events={dateEventMap[getDateKey(currentDate)] || []}
                    />}
                    {mode === EMode.MONTH &&
                    <Month
                        onEventClick={onEvent}
                        dateEventMap={dateEventMap}
                        monthDaysMatrix={currenMonthDaysMatrix}
                    />}
                    {mode === EMode.WEEK &&
                    <Week
                        onEventClick={onEvent}
                        dateEventMap={dateEventMap}
                        weekDaysArray={currenWeekDaysArray}
                    />}
                </div>

            </div>
            {
                showAddAppointment &&
                <AddAppointmentModal
                    event={currentEvent}
                    onCancel={() => {
                        setCurrentEvent(undefined);
                        setShowAddAppointment(false);
                    }}
                    onSuccess={() => {
                        setCurrentEvent(undefined);
                        setShowAddAppointment(false);
                        onRefresh();
                    }}
                    api={api}
                />
            }
            {
                showAddEvent &&
                <AddEventModal
                    event={currentEvent}
                    onCancel={() => {
                        setCurrentEvent(undefined);
                        setShowAddEvent(false);
                    }}
                    onSuccess={() => {
                        setCurrentEvent(undefined);
                        setShowAddEvent(false);
                        onRefresh();
                    }}
                    api={api}
                />
            }
            {
                showAddBlocktime &&
                <AddBlocktimeModal
                    event={currentEvent}
                    onCancel={() => {
                        setCurrentEvent(undefined);
                        setShowAddBlocktime(false);
                    }}
                    onSuccess={() => {
                        setCurrentEvent(undefined);
                        setShowAddBlocktime(false);
                        onRefresh();
                    }}
                    api={api}
                />
            }
            {
                showCommonEdit &&
                <EditCommonEventModal
                    event={currentEvent}
                    onCancel={() => {
                        setCurrentEvent(undefined);
                        setShowCommonEdit(false);
                    }}
                    onSuccess={() => {
                        setCurrentEvent(undefined);
                        setShowCommonEdit(false);
                        onRefresh();
                    }}
                    api={api}
                />
            }
        </div>
    );
};

export default Page;
